.InfoBar {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100vh;
    background-color: rgba(255,255,255,0.7);
    width: 400px;
    margin: 0px;
    padding: 0px;
    padding-left: 10px
}

.InfoBar h1 {
    font-size: 16pt;
}

.InfoBar h2 {
    font-size: 14pt;
    text-decoration: underline;
    margin-top: 30px;
}